<template>
  <div class="box">
    <div class="home" ref="bigbox">
      <div class="wzs-banner">
        <img src="../assets/cj/text.png" alt="" />
      </div>
      <div class="info">
        <div class="info-box">
          <div class="head"><h5>插件下载</h5></div>
          <ul class="xz">
            <li>
              <div class="left">
                <img src="../assets/cj/icon.png" alt="" />
                <div class="xz-text">
                  <h5>
                    锵行京东助手_2345浏览器
                    <span>2021-05-14 17:55:22 0.47MB</span>
                  </h5>
                  <p>
                    功能强大的京挑客辅助插件，支持佣金查询、优惠券查询、销量轨迹查询、店铺数据查询、主图视频下载、优质文案推荐、爆款商品等众多功能。只支持2345浏览器
                  </p>
                </div>
              </div>
              <div class="right">
                <img src="../assets/cj/download.png" alt="" />
                <h5>下载</h5>
              </div>
            </li>
            <li>
              <div class="left">
                <img src="../assets/cj/icon.png" alt="" />
                <div class="xz-text">
                  <h5>
                    锵行京东助手_2345浏览器
                    <span>2021-05-14 17:55:22 0.47MB</span>
                  </h5>
                  <p>
                    功能强大的京挑客辅助插件，支持佣金查询、优惠券查询、销量轨迹查询、店铺数据查询、主图视频下载、优质文案推荐、爆款商品等众多功能。只支持2345浏览器
                  </p>
                </div>
              </div>
              <div class="right">
                <img src="../assets/cj/download.png" alt="" />
                <h5>下载</h5>
              </div>
            </li>
            <li>
              <div class="left">
                <img src="../assets/cj/icon.png" alt="" />
                <div class="xz-text">
                  <h5>
                    锵行京东助手_2345浏览器
                    <span>2021-05-14 17:55:22 0.47MB</span>
                  </h5>
                  <p>
                    功能强大的京挑客辅助插件，支持佣金查询、优惠券查询、销量轨迹查询、店铺数据查询、主图视频下载、优质文案推荐、爆款商品等众多功能。只支持2345浏览器
                  </p>
                </div>
              </div>
              <div class="right">
                <img src="../assets/cj/download.png" alt="" />
                <h5>下载</h5>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.box {
  height: 2000px;
  .home {
    position: relative;
    text-align: center;
    .wzs-banner {
      width: 100%;
      min-width: 1320px;
      height: 980px;
      background-image: url("../assets/cj/banner.png");
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      img {
        margin-top: 260px;
      }
    }
    .info {
      position: absolute;
      left: 0;
      top: 780px;
      z-index: 9;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;

      .info-box {
        width: 1300px;
        min-height: 400px;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: 0 0 24px 0 rgb(47 102 173 / 18%);
        // border-radius: 30px;
        box-sizing: border-box;
        text-align: left;
        .head {
          height: 71px;
          background: rgba(245, 248, 250, 0.39);
          h5 {
            width: 72px;
            height: 71px;
            font-size: 18px;
            font-weight: 400;
            line-height: 71px;
            color: #8f93a7;
            margin-left: 50px;
          }
        }
        .xz {
          li {
            list-style: none;
            display: flex;
            padding: 33px 78px 41px 59px;
            .left {
              border-right: 5px solid #d9d9d9;
              width: 90%;
              display: flex;
              align-items: center;
              img {
                width: 105px;
              }
              .xz-text {
                padding: 0 35px;
                box-sizing: border-box;
                h5 {
                  font-size: 28px;
                  line-height: 18px;
                  color: #3e3e3e;
                  margin-bottom: 20px;
                  display: flex;
                  justify-content: space-between;
                  span {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #acacac;
                    margin-right: 20px;
                  }
                }
                p {
                  font-size: 18px;
                  line-height: 30px;
                  color: #787878;
                }
              }
            }
            .right {
              flex-grow: 1;
              text-align: center;
              cursor: pointer;
              margin-left: 10px;
              img {
                width: 60px;
              }
              h5 {
                font-size: 16px;
                line-height: 18px;
                color: #347dc0;
              }
            }
          }
        }
      }
    }
  }
}
</style>